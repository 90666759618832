import Raven from 'raven-js'
import {assign as assign_, get as get_, sortBy as sortBy_} from 'lodash'
import * as menus from './wrappers/menus'
import * as pagesGroup from './wrappers/pagesGroup'
import * as pages from './wrappers/pages'
import * as routers from './wrappers/routers'
import * as tpa from './wrappers/tpa'
import * as applicationState from './applicationState'
import * as constants from './constants'
import {isHorizontalLayoutEnabled, isManageMemberPagesEnabled} from '../utils/experiments'
import {getIsResponsiveEditor, getIsADI} from './services/applicationState'
import * as state from './services/applicationState'

async function setProtectedPage(editorSDK, appToken, pageData) {
    const existingPageData = await editorSDK.pages.data.get(appToken, {pageRef: pageData.pageRef})
    if (!get_(existingPageData, 'pageSecurity.requireLogin')) {
        await pages.updatePageData({
            editorSDK,
            appToken,
            pageRef: pageData.pageRef,
            pageData: {pageSecurity: {requireLogin: true}}})
    }
}

// TODO - Maybe this could be replaced with some kind of lock mechanism? WEED-5953
let pagesToConnect = []
let connecting = false

// eslint-disable-next-line no-unused-vars
async function connectPageToMembers({editorSDK, appToken, menuIds, pageData, showInLoginMenu, routerConfig, appDefinitionId, loginMenuTitle, urlOverride = null, showInMemberMenu = true, showInIconsMenu = false}) {
    const args = arguments[0]
    const callback = new Promise(function (resolve) {
        pagesToConnect.push({
            resolve,
            args
        })
    })
    if (!connecting) {
        connecting = true
        await connectPagesInQueue()
    }
    await Promise.all([callback])
}

async function connectPagesInQueue() {
    if (pagesToConnect.length) {
        let page = pagesToConnect.pop()
        await connectPage(page.args)
        page.resolve()
        await connectPagesInQueue()
    } else {
        connecting = false
    }
}

async function createMenuItem({editorSDK, appToken, menuId, linkData}) {
    const appRouters = await routers.getAll(editorSDK, appToken)
    const menuItems = await menus.getMenuItems({editorSDK, appToken, menuId})
    const newMenuItem = menus.createNewMenuItem(linkData)
    menuItems.push(newMenuItem)
    appRouters.forEach(router => {
        return router.config && router.config.patterns && Object.keys(router.config.patterns).forEach(pattern => {
            const menuItem = menuItems.filter(item => item.link.routerId === router.id && `/${item.link.innerRoute}` === pattern).pop()
            if (menuItem) {
                menuItem.order = router.config.patterns[pattern].appData.menuOrder || constants.DEFAULT_MENU_ORDER
            }
        })
    })

    const reorderedItems = sortBy_(menuItems, item => item.order || constants.DEFAULT_MENU_ORDER)
    return await menus.updateMenuItems({editorSDK, appToken, menuId, items: reorderedItems})
}

async function navigateToFirstPrivatePage(editorSDK, appToken) {
    const allRouters = await routers.getAll(editorSDK, appToken)
    const privateRouter = allRouters.find(router => router.config.type === 'private')
    const privatePage = privateRouter.pages[0]

    if (!privatePage || !privatePage.pageRef) {
        const error = new Error('Could not retrieve a private page when navigating to first private page')
        Raven.captureException(error)
        throw error
    }

    await pages.navigateToPageRef({editorSDK, appToken, pageRef: privatePage.pageRef})
}

async function connectPage({editorSDK, appToken, menuIds, pageData, showInLoginMenu, routerConfig, appDefinitionId, loginMenuTitle, urlOverride = null, showInMemberMenu = true, showInIconsMenu = false}) {
    const {pageRef, pageUriSEO, isPrivate} = pageData
    const routerRef = await routers.connectPageToRouter({
        editorSDK,
        appToken,
        routerConfig,
        pageRef,
        pageUriSEO,
        isPrivate,
        urlOverride
    })
    const routerIdPromise = routers.getId(editorSDK, appToken, routerRef)
    let innerRoute = urlOverride || pageUriSEO
    let protectedPagePromise, memberMenuPromise, loginMenuPromise, iconsMenuPromise

    if (isPrivate) {
        protectedPagePromise = setProtectedPage(editorSDK, appToken, pageData)
    } else {
        innerRoute = '{userName}/' + innerRoute
    }

    const membersMenulinkData = {
        label: pageData.title,
        link: {
            type: 'DynamicPageLink',
            routerId: await routerIdPromise,
            innerRoute: innerRoute
        }
    }
    if (!showInMemberMenu) {
        membersMenulinkData.isVisible = false
        membersMenulinkData.isVisibleMobile = false
    }
    memberMenuPromise = createMenuItem({editorSDK, appToken, menuId: menuIds.members, linkData: membersMenulinkData})

    if (showInLoginMenu) {
        const loginMenuLinkData = {
            label: loginMenuTitle || pageData.title,
            link: {
                type: 'DynamicPageLink',
                routerId: await routerIdPromise,
                innerRoute: innerRoute
            }
        }
        loginMenuPromise = createMenuItem({editorSDK, appToken, menuId: menuIds.login, linkData: loginMenuLinkData})
    }
    loginMenuPromise = loginMenuPromise || Promise.resolve()

    if (showInIconsMenu) {
        const iconsMenuLinkData = {
            label: pageData.title,
            link: {
                type: 'DynamicPageLink',
                routerId: await routerIdPromise,
                innerRoute: innerRoute
            },
            iconRef: {svgId: '2c36dc006cb94853a49daee7e821f642.svg', type: 'VectorImage'}
        }
        iconsMenuPromise = createMenuItem({editorSDK, appToken, menuId: menuIds.icons, linkData: iconsMenuLinkData})
    }
    iconsMenuPromise = iconsMenuPromise || Promise.resolve()

    const pagesGroupPromise = pagesGroup.addPageToGroup(editorSDK, appToken, constants.MEMBERS_PAGES_GROUP_NAME, pageRef.id)
    await Promise.all([protectedPagePromise, memberMenuPromise, loginMenuPromise, iconsMenuPromise, pagesGroupPromise])
}

async function add({editorSDK, appToken, payload, method = 'addComponent'}) {

    if (payload.pageId && await tpa.isAppSectionInstalled({editorSDK, appToken, appDefinitionId: payload.appDefinitionId, sectionId: payload.pageId})) {
        return
    }

    const isNotifications = payload.pageId === 'notifications_app'

    const routerConfig = {
        appData: {
            numbers: payload.numbers,
            appDefinitionId: payload.appDefinitionId,
            appPageId: payload.pageId,
            menuOrder: payload.menuOrder || constants.DEFAULT_MENU_ORDER,
            visibleForRoles: payload.visibleForRoles || []
        }
    }
    if (payload.socialHome) {
        routerConfig.socialHome = true
    }
    delete payload.numbers

    const menuIds = menus.getMenuIds()

    const options = {
        appDefinitionId: payload.appDefinitionId,
        componentType: 'PAGE',
        page: {
            pageId: payload.pageId,
            requireLogin: !payload.social,
            shouldNavigate: !!payload.shouldNavigate,
            showInLoginMenu: !!payload.showInLoginMenu
        }
    }

    if (method === 'addComponent') {
        options.page = {
            pageId: payload.pageId,
            requireLogin: !payload.social,
            shouldNavigate: !!payload.shouldNavigate,
            showInLoginMenu: !!payload.showInLoginMenu
        }
    } else if (method === 'addApplication') {
        assign_(options, {
            requireLogin: !payload.social,
            shouldNavigate: !!payload.shouldNavigate,
            showInLoginMenu: !!payload.showInLoginMenu
        })
    }

    const isHorizontalLayout = !getIsADI() && (await isHorizontalLayoutEnabled() || getIsResponsiveEditor())
    assign_(options, isHorizontalLayout ? constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL : constants.SECTION_DEFAULT_LAYOUT)

    try {
        const data = await tpa[method](editorSDK, appToken, options)
        await connectPageToMembers({
            editorSDK,
            appToken,
            routerConfig,
            urlOverride: payload.urlOverride,
            pageData: assign_({isPrivate: !payload.social}, data),
            showInLoginMenu: payload.showInLoginMenu,
            showInMemberMenu: payload.showInMemberMenu,
            appDefinitionId: payload.appDefinitionId,
            loginMenuTitle: payload.loginMenuTitle,
            showInIconsMenu: isNotifications,
            menuIds: menuIds
        })
    } catch (e) {
        console.log('error while installing tpa', e, payload)
        throw e
    }
}

async function removePage({editorSDK, appToken, options, removeDependantApps = true}) {
    let {returnValue: removedInnerRoute, appDefinitionId} = await routers.onRouterPageDelete(editorSDK, appToken, options.pageRole, options.pageRef)
    if (!removedInnerRoute) {
        return
    }
    removedInnerRoute = removedInnerRoute.replace(/\/?(.*)/, '$1')
    await menus.removePatternFromAllMenus({editorSDK, appToken, pattern: removedInnerRoute})
    if (removeDependantApps) {
        await gcPages({editorSDK, appToken, appDefinitionId})
    }

    await editorSDK.document.application.reloadManifest()
}

async function gcPages({editorSDK, appToken, appDefinitionId}) {
    const hasNoDependencies = (allApps, appDefId) => !allApps.some(app => app.appDefinitionId === appDefId)
    const integratedAppsWithoutDeleted = Object
        .values({...state.getAllIntegratedApps(), [appDefinitionId]: undefined})
        .filter((x) => x)
        .flat()

    const apps = applicationState.getPageDependencies()
    // eslint-disable-next-line guard-for-in
    for (let dependantAppDefinitionId in apps) {
        const app = apps[dependantAppDefinitionId]
        app.delete(appDefinitionId)
        applicationState.setPageDependencies(apps)
        const managePagesEnabled = await isManageMemberPagesEnabled()
        const hasNewApiDependencies = managePagesEnabled ? hasNoDependencies(integratedAppsWithoutDeleted, dependantAppDefinitionId) : true

        if (app.size === 0 && hasNewApiDependencies) {
            // REMOVE the page by key
            delete apps[dependantAppDefinitionId]
            applicationState.setPageDependencies(apps)
            await routers.removePageByAppDefinitionId(
                editorSDK,
                appToken,
                dependantAppDefinitionId,
                await pages.getMainMembersPageRef({ editorSDK }))
        }
    }
}

async function isInMembersAreaSubPage({editorSDK, appToken}) {
    const currentPageRef = await editorSDK.pages.getCurrent(appToken)
    const allMARouters = await routers.getAll(editorSDK, appToken)
    const allMAPages = [].concat(...allMARouters.map(router => router.pages))
    return !!allMAPages.find(page => page.pageRef.id === currentPageRef.id)
}

async function navigateToHomePage({editorSDK, appToken}) {
    const pageRef = await pages.getHomePage({editorSDK, appToken})
    return pages.navigateToPageRef({editorSDK, appToken, pageRef})
}

export {
    add,
    removePage,
    navigateToFirstPrivatePage,
    connectPageToMembers,
    createMenuItem,
    isInMembersAreaSubPage,
    navigateToHomePage
}
